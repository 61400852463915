import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { apiPrefixInterceptor } from './core/interceptos/api-prefix.interceptor';
import { es_ES, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { loadingInterceptor } from './core/interceptos/loading.interceptor';
import { provideStore } from '@ngrx/store';
import { authReducer } from './stores/auth/auth.reducer';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { authMetaReducers } from './stores/auth/auth.metareducer';
import { provideEffects } from '@ngrx/effects';
import * as authEffects from './stores/auth/auth.effects';

registerLocaleData(es);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([apiPrefixInterceptor, loadingInterceptor]),
      withFetch()
    ),
    provideNzI18n(es_ES),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideStore({ auth: authReducer }, { metaReducers: authMetaReducers }),
    provideEffects([authEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
  ],
};
