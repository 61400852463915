import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { RoutePathType } from '../../shared/enums';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '../../stores/auth/auth.selectors';
import { map } from 'rxjs';

export const noUserGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  const selectIsAuthenticated$ = store.select(selectIsAuthenticated);

  return selectIsAuthenticated$.pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        router.navigate([RoutePathType.URL_LANDING_HOME]);
        return false;
      } else {
        return true;
      }
    })
  );
};
