import { IResponseLogin, IUser } from '../../shared/interfaces';

export interface AuthState {
  user: IUser | null;
  isAuthenticated: boolean;
  id: string;
  token?: IResponseLogin | null;
  loading: boolean;
  error: string;
  role: string;
}

export const initialAuthState: AuthState = {
  user: null,
  isAuthenticated: false,
  id: '',
  token: null,
  loading: false,
  error: '',
  role: '',
};
