import { MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = 'fintekclub'; // Cambia esto por tu clave secreta

function encrypt(value: any): string {
  return CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
}

function decrypt(text: string): any {
  const bytes = CryptoJS.AES.decrypt(text, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export function authLocalStorageSyncReducer(reducer: any): any {
  return localStorageSync({
    keys: [
      {
        auth: {
          serialize: (authState) => encrypt(authState),
          deserialize: (authState) => decrypt(authState),
        },
      },
    ],
    rehydrate: true,
    storage: localStorage,
  })(reducer);
}

export const authMetaReducers: MetaReducer<any>[] = [
  authLocalStorageSyncReducer,
];
