import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject, switchMap } from 'rxjs';
import {
  JwtService,
  UserFixedTermDepositHistoryService,
} from './shared/services';
import { IResponseOk } from './shared/interfaces';
import { FixedTermDepositHistoryStorageService } from './shared/services/fixed-term-deposit-history-storage.service';
import { Store } from '@ngrx/store';
import { selectAuth } from './stores/auth/auth.selectors';
import * as AuthActions from './stores/auth/auth.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'frontend-fintek-club';
  public createHistoryFixedTermDeposit$ = new Subject<any>();

  public selectAuth$ = this.store.select(selectAuth);
  private destroyRef = inject(DestroyRef);

  constructor(
    private store: Store,
    private userFixedTermDepositHistoryService: UserFixedTermDepositHistoryService,
    private jwtService: JwtService,
    private fixedTermDepositHistoryStorageService: FixedTermDepositHistoryStorageService
  ) {
    this.createHistoryFixedTermDeposit();
    this.selectAuth$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((auth) => {
        if (auth.user) {
          const isTokenExpired = this.jwtService.isTokenExpired(
            auth.token?.accessToken || ''
          );
          if (isTokenExpired) {
            this.store.dispatch(AuthActions.logout());
          } else {
            const dataRequestCreateHistoryFixedTermDeposit =
              this.fixedTermDepositHistoryStorageService.getFixedTermDepositHistoryData();

            this.fixedTermDepositHistoryStorageService.clearFixedTermDepositHistoryData();

            if (
              dataRequestCreateHistoryFixedTermDeposit &&
              dataRequestCreateHistoryFixedTermDeposit.length > 0
            ) {
              const lastItem =
                dataRequestCreateHistoryFixedTermDeposit[
                  dataRequestCreateHistoryFixedTermDeposit.length - 1
                ];
              lastItem.user = auth.user;
              this.createHistoryFixedTermDeposit$.next(lastItem);
            }
          }
        }
      });
  }

  public ngOnInit() {
    console.log('Cargó página');
  }

  public onClick() {
    window.open('https://wa.me/51917325417', '_blank');
  }

  public createHistoryFixedTermDeposit(): void {
    this.createHistoryFixedTermDeposit$
      .pipe(
        switchMap((data) => {
          return this.userFixedTermDepositHistoryService.create(data);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (response: IResponseOk) => {
          this.fixedTermDepositHistoryStorageService.clearFixedTermDepositHistoryData();
        },
        error: () => {
          this.createHistoryFixedTermDeposit();
        },
      });
  }
}
