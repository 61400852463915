import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import * as AuthActions from './auth.actions';
import { AuthService, JwtService, UserService } from '../../shared/services';
import { catchError, map, mergeMap, of } from 'rxjs';
import { IResponseLogin, IUser } from '../../shared/interfaces';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { RoutePathType } from '../../shared/enums';
import { Router } from '@angular/router';

export const login = createEffect(
  (
    actions$ = inject(Actions),
    authService = inject(AuthService),
    jwtService = inject(JwtService),
    userService = inject(UserService),
    notification = inject(NzNotificationService),
    router = inject(Router)
  ) => {
    return actions$.pipe(
      ofType(AuthActions.login),
      mergeMap(({ email, password }) =>
        authService.login(email, password).pipe(
          mergeMap((responseLogin) => {
            const dataDecodeAuth = jwtService.getTokenPayload(
              responseLogin.data?.accessToken as string
            );
            return userService
              .profile(
                dataDecodeAuth.document,
                responseLogin.data?.accessToken as string
              )
              .pipe(
                map((responseProfile) => {
                  notification.create(
                    'success',
                    '¡Bienvenido! Ha iniciado sesión correctamente. ',
                    '',
                    { nzPlacement: 'top' }
                  );
                  router.navigate([RoutePathType.URL_LANDING_HOME]);
                  return AuthActions.loginSuccess({
                    id: dataDecodeAuth.document,
                    token: responseLogin.data as IResponseLogin,
                    role: dataDecodeAuth.role,
                    user: responseProfile.data as IUser,
                  });
                }),
                catchError((err: { message: string }) => {
                  notification.create('error', err.message, '', {
                    nzPlacement: 'top',
                  });
                  return of(AuthActions.loginFailure({ error: err.message }));
                })
              );
          }),
          catchError((err: { message: string }) => {
            notification.create('error', err.message, '', {
              nzPlacement: 'top',
            });
            return of(AuthActions.loginFailure({ error: err.message }));
          })
        )
      )
    );
  },
  { functional: true }
);
