import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export const apiPrefixInterceptor: HttpInterceptorFn = (req, next) => {
  const apiPrefix = environment.serverUrl;

  if (!/^(http|https):/i.test(req.url)) {
    req = req.clone({
      url: apiPrefix + req.url,
    });
  }
  return next(req);
};
