// auth.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { AuthState, initialAuthState } from './auth.state';

export const authReducer = createReducer(
  initialAuthState,
  on(
    AuthActions.login,
    (state): AuthState => ({
      ...state,
      user: null,
      isAuthenticated: false,
      id: '',
      token: null,
      loading: true,
      error: '',
      role: '',
    })
  ),
  on(
    AuthActions.loginSuccess,
    (state, { id, token, role, user }): AuthState => ({
      ...state,
      user,
      id,
      isAuthenticated: true,
      token,
      loading: false,
      role,
    })
  ),
  on(
    AuthActions.loginFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
      loading: false,
    })
  ),
  on(
    AuthActions.logout,
    (state): AuthState => ({
      ...state,
      user: null,
      isAuthenticated: false,
      id: '',
      token: null,
      loading: false,
      error: '',
      role: '',
    })
  )
);
